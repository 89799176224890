import {
  PageData,
  ComponentRef,
  PageRef,
  ComponentDefinition,
} from '@wix/editor-platform-sdk-types';

import { ComponentType, ComponentLayout, EditorAppContext } from './types';
import { getSearchResultsHeaderComponentDefinition } from './componentDefinitions';
import { getComponent } from './editorSDKHelpers';

export async function getSearchResultsComponent(appContext: EditorAppContext) {
  return getComponent(
    appContext,
    ({ componentType, componentData }) =>
      componentData &&
      componentData.appDefinitionId === appContext.appDefinitionId &&
      componentType === ComponentType.TPA,
  );
}

export async function getSearchResultsPage(
  appContext: EditorAppContext,
): Promise<PageRef> {
  const { appDefinitionId, editorSDK } = appContext;
  // FIXME: sometimes result of `getSearchResultsComponent` is empty.
  // Need to handle it properly.
  // JIRA: https://jira.wixpress.com/browse/SER-1332
  return editorSDK.components.getPage(appDefinitionId, {
    componentRef: (await getSearchResultsComponent(appContext))!,
  });
}

// NOTE: search results layout https://zpl.io/VOxkPyY
// https://docs.google.com/presentation/d/1cxgiYIkRgOVdy-xUv090CfDSdapppE9-IvGejs2qHhE/edit#slide=id.g5f497222a2_4_39
const SEARCH_RESULTS_HEADER_LAYOUT = {
  width: 600,
  height: 43,
  marginTop: 52,
  marginBottom: 52,
};
const SEARCH_RESULTS_LAYOUT = {
  marginTop: 16,
};

export async function addSearchResultsHeader(
  appContext: EditorAppContext,
  {
    searchResultsPageRef,
    searchResultsRef,
  }: {
    searchResultsPageRef: PageRef;
    searchResultsRef: ComponentRef;
  },
): Promise<void> {
  const { appDefinitionId, editorSDK, translate } = appContext;
  const searchResultsParentRef = (
    await editorSDK.components.getAncestors(appDefinitionId, {
      componentRef: searchResultsRef,
    })
  )[0];
  const searchResultsPageLayout = (await editorSDK.components.layout.get(
    appDefinitionId,
    {
      componentRef: searchResultsPageRef as ComponentRef,
    },
  )) as ComponentLayout;

  const headerLayout = {
    x: Math.round(
      searchResultsPageLayout.bounding.width / 2 -
        SEARCH_RESULTS_HEADER_LAYOUT.width / 2,
    ),
    y: SEARCH_RESULTS_HEADER_LAYOUT.marginTop,
    width: SEARCH_RESULTS_HEADER_LAYOUT.width,
    height: SEARCH_RESULTS_HEADER_LAYOUT.height,
  };

  const headerLayoutResponsive: ComponentDefinition['layouts'] = {
    componentLayout: {
      height: { type: 'px', value: SEARCH_RESULTS_HEADER_LAYOUT.height },
      width: { type: 'px', value: SEARCH_RESULTS_HEADER_LAYOUT.width },
      type: 'ComponentLayout',
    },
    itemLayout: {
      id: 'item-layout',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      alignSelf: 'start',
      justifySelf: 'center',
      type: 'GridItemLayout',
      margins: {
        top: { value: SEARCH_RESULTS_HEADER_LAYOUT.marginTop, type: 'px' },
        bottom: {
          value: SEARCH_RESULTS_HEADER_LAYOUT.marginBottom,
          type: 'px',
        },
      },
    },
  };
  // NOTE: magic calculation to make bottom margin 52px (https://zpl.io/VOxkPyY)
  const headerMarginBottomFixed =
    SEARCH_RESULTS_HEADER_LAYOUT.marginBottom -
    SEARCH_RESULTS_LAYOUT.marginTop -
    9;

  await editorSDK.components.layout.update(appDefinitionId, {
    componentRef: searchResultsRef,
    layout: {
      x: 0,
      y: headerLayout.y + headerLayout.height + headerMarginBottomFixed,
    },
  });

  return editorSDK.components
    .add(appDefinitionId, {
      pageRef: searchResultsParentRef,
      componentDefinition: getSearchResultsHeaderComponentDefinition({
        text: translate('searchResults.searchBox.header.text'),
        layout: headerLayout,
        layouts: headerLayoutResponsive,
      }),
    })
    .then(() => {})
    .catch(() => {
      // EditorX does not allow adding components directly to page.
      // There is a WIP PR that supposedly fixes this https://github.com/wix-private/responsive-editor-packages/pull/22518
    });
}

export async function updateSearchResultsPageData(
  appContext: EditorAppContext,
  { searchResultsPageRef }: { searchResultsPageRef: PageRef },
): Promise<void> {
  const { appDefinitionId, editorSDK } = appContext;
  return editorSDK.pages.data.update(appDefinitionId, {
    pageRef: searchResultsPageRef,
    data: {
      hidePage: true,
      indexable: false,
    } as PageData,
  });
}

export async function navigateToSearchResultsPage(
  appContext: EditorAppContext,
) {
  const { editorSDK, appDefinitionId } = appContext;
  const searchResultsPage = await getSearchResultsPage(appContext);
  await editorSDK.document.pages.navigateTo(appDefinitionId, {
    pageRef: searchResultsPage,
  });
}
